.WhiteButtonT {
    color: #FFFFFF;
    background: transparent;
    border-radius: 0;
    border: 2px solid #FFFFFF;
    span {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &:hover, &:focus {
        background-color: #FFFFFF;
        border-color: #FFFFFF !important;
    }
}

.WhiteButtonNt {
    color: #FFFFFF;
    background: rgba(255,255,255, 0.1);
    border-radius: 0;
    border: 2px solid #FFFFFF;
    span {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &:hover, &:focus {
        background-color: #FFFFFF;
        border-color: #FFFFFF !important;
    }
}