@import '../../../../../assets/styles/landings/index.scss';

.benefits {
    background: #F1F5F9;
    padding: 56px 0 ;
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 32px;
        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        .benefitsItem {
            display: flex;
            align-items: center;
            gap: 16px;
            &__icon {
                height: 80px;
                width: 80px;
                @media screen and (min-width: 991px) {
                    height: 120px;
                    width: 120px;
                }
            }
            &__text{
                flex: 1;
                p{
                    font-size: 16px;
                    font-weight: 400;
                    color: #64748B;
                }
            }
            @media screen and (min-width: 576px) {
                gap: 32px;
                &__icon{
                    display: block;
                }
            }
            @media screen and (min-width: 768px) {
                &__text {
                    p{
                        font-size: 16px;
                    }
                }
            }
            @media screen and (min-width: 992px) {
                &__text {
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
