.RedButton {
    background: transparent;
    border-radius: 0;
    border: 2px solid #f87171;
    font-weight: 500;
    span {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}
