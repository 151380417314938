@import '../../../../../assets/styles/landings/index.scss';

.Steps {
    background-color: #FFFFFF;
    @media (min-width: 320px) {
        padding: 32px 0 65px 0;
    }
    @media (min-width: 576px) {
        padding: 32px 0 80px 0;
    }
    @media (min-width: 992px) {
        padding: 32px 0 120px 0;
    }

    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 32px;
        @media (min-width: 320px) {
            grid-template-columns: 1fr;
            row-gap: 24px;
        }
        @media (min-width: 576px) {
            row-gap: 32px;
        }
        @media (min-width: 768px) {

        }
        @media (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }

    }

    &__card {
        position: relative;
        background-color: #EC557C;
        border-radius: 3px;
        @media (min-width: 320px) {
            min-height: 160px;
            padding: 0 24px 0 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
        @media (min-width: 576px) {
            min-height: 200px;
            padding: 0 77px 0 0;
        }
        @media (min-width: 768px) {
            align-items: center;
            padding: 0;
        }
        @media (min-width: 992px) {
            min-height: 230px;
            padding: 32px 8px;
            display: block;
            text-align: center;
        }
        @media (min-width: 1200px) {
            min-height: 280px;

            padding: 40px 32px;
            text-align: left;
        }
        @media (min-width: 1600px) {
            min-height: 300px;
        }

    }

    &__cardTitle {
        font-weight: 500;
        color: #FFFFFF;
        @media (min-width: 320px) {
            font-size: 18px;
            line-height: 27px;
        }
        @media (min-width: 576px) {
            font-size: 28px;
            line-height: 42px;
        }
        @media (min-width: 768px) {

        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }

    }

    &__cardFirst {
        background: url('../../../../../assets/images/affiliate/webmaster/rocket.png') left bottom no-repeat #EC557C;
        @media (min-width: 320px) {
            background-size: 109px 90px;
        }
        @media (min-width: 576px) {
            background-size: 180px 150px;
        }
        @media (min-width: 1200px) {
            background-size: 238px 197px;
        }
    }

    &__cardSecond {
        background: url('../../../../../assets/images/affiliate/webmaster/man.png') left bottom no-repeat #EC557C;
        @media (min-width: 320px) {
            background-size: 130px 90px;
        }
        @media (min-width: 576px) {
            background-size: 218px 150px;
        }
        @media (min-width: 1200px) {
            background-size: 278px 191px;
        }
    }

    &__cardThird {
        background: url('../../../../../assets/images/affiliate/webmaster/money.png') left bottom no-repeat #EC557C;
        @media (min-width: 320px) {
            background-size: 105px 90px;
        }
        @media (min-width: 576px) {
            background-size: 175px 150px;
        }
        @media (min-width: 1200px) {
            background-size: 217px 186px;
        }
    }

    &__actions {
        margin-top: 40px;
        margin-left: auto;

        span {
            font-size: 16px;
            line-height: 24px;
        }

        @media (min-width: 320px) {
            max-width: 100%;
        }
        @media (min-width: 576px) {
            max-width: 150px;
        }
    }
}
