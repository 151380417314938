@import '../../../../../assets/styles/landings/index.scss';

.Tools {
    background-color: #FFFFFF;
    @media (min-width: 320px) {
        margin: 40px 0;
    }
    @media (min-width: 576px) {
        margin: 65px 0;
    }
    @media (min-width: 992px) {
        margin: 80px 0;
    }
    &__items {
        display: grid;
        column-gap: 32px;
        row-gap: 32px;
        @media (min-width: 320px) {
            grid-template-columns: 1fr;
            row-gap: 24px;
        }
        @media (min-width: 576px) {
            row-gap: 32px;
        }
        @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
    
    }
    &__card {
        background-color: #FFFFFF;
    }
    &__cardIcon {
        position: relative;
        margin-bottom: 5px;
        @media (min-width: 320px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                max-width: 80%;
            }
        }
        @media (min-width: 576px) {
            display: block;
            left: -15px;
            img {
                max-width: 100%;
            }
        }
    }
    &__cardTitle {
        font-weight: 500;
        color: #1E293B;
        margin-bottom: 16px;
        @media (min-width: 320px) {
            font-size: 18px;
            line-height: 27px;
        }
        @media (min-width: 576px) {
            font-size: 24px;
            line-height: 35px;
        }
    }
    &__cardContent {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #64748B;
        @media (min-width: 320px) {
            font-size: 16px;
            line-height: 24px;
        }
        @media (min-width: 576px) {
            font-size: 18px;
            line-height: 27px;
        }
    }
}
