@import '../../../../../assets/styles/landings/index.scss';

.Advantages {
    background-color: #EC557C;
    @media (min-width: 320px) {
        padding: 32px 0;
        margin-bottom: 40px;
    }
    @media (min-width: 576px) {
        padding: 48px 0;
        margin-bottom: 64px;
    }
    @media (min-width: 992px) {
        margin-bottom: 80px;
    }
    @media (min-width: 1200px) {
        padding: 56px 0;
        margin-bottom: 80px;
    }
    &__title {
        color: #FFFFFF;
    }
    &__items {
        display: grid;
        column-gap: 32px;
        @media (min-width: 320px) {
            grid-template-columns: 1fr;
            row-gap: 16px;
        }
        @media (min-width: 576px) {
            row-gap: 32px;
        }
        @media (min-width: 992px) {
            grid-template-columns: 1fr 1fr;
        }

    }
    &__card {
        background-color: #FFFFFF;
        border-radius: 3px;
        padding: 32px 24px;
    }
    &__cardIcon {
        color: #EC557C;
        @media (min-width: 320px) {
            font-size: 28px;
            margin-bottom: 8px;
        }
        @media (min-width: 768px) {
            font-size: 40px;
            margin-bottom: 16px;
        }
    }
    &__cardTitle {
        font-weight: 500;
        color: #1E293B;
        margin-bottom: 16px;
        @media (min-width: 320px) {
            font-size: 18px;
            line-height: 27px;
        }
        @media (min-width: 768px) {
            font-size: 24px;
            line-height: 35px;
        }
    }
    &__cardContent {
        font-weight: 400;
        color: #64748B;
        @media (min-width: 320px) {
            font-size: 16px;
            line-height: 24px;
        }
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 27px;
        }
    }
}
