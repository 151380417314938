@import '../../../../../assets/styles/landings/index.scss';

.Header {
    background-color: #EC557C;
    position: relative;
    padding-bottom: 40px;
    &__wrapper {
        //
        @media (min-width: 320px) {
            padding: 40px 0;
            min-height: auto;
        }
        @media (min-width: 576px) {
            padding: 65px 0 80px 0;
        }
        @media (min-width: 992px) {
            padding: 80px 0 122px 0;
        }
    }
    &__content {
        //
        @media (min-width: 320px) {
            max-width: 100%;
        }
        @media (min-width: 768px) {
            max-width: 580px;
        }
        @media (min-width: 992px) {
            max-width: 750px;
        }
    }
    &__title {
        color: #FFFFFF;
        @media (min-width: 320px) {
            font-weight: 500;
            font-size: 28px;
            line-height: 42px;
            margin-bottom: 16px;
        }
        @media (min-width: 576px) {
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
        }
        @media (min-width: 768px) {
            font-size: 56px;
            line-height: 62px;
        }
        @media (min-width: 992px) {
            font-size: 60px;
            line-height: 72px;
            margin-bottom: 24px;
        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }
    }
    &__subTitle {
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        @media (min-width: 320px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
            max-width: 580px;
        }
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 27px;
        }
        @media (min-width: 992px) {
            margin-bottom: 40px;
        }
        @media (min-width: 1200px) {
            max-width: 540px;
        }
    }
    &__image {
        background-image: url('../../../../../assets/images/affiliate/webmaster/bg-webmaster.png');
        background-size: contain;
        position: absolute;
        width: 670px;
        height: 348px;
        bottom: 40px;
        right: 0;
        @media (min-width: 320px) {
            display: none;
        }
        @media (min-width: 1200px) {
            display: block;
        }
    }
    &__button {
        @media (min-width: 320px) {
            width: 100%;
        }
        @media (min-width: 576px) {
            width: auto;
        }
    }
    &__buttonIcon {
        margin-left: 20px;
    }
}
