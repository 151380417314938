.landingContainer {
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 320px) {
        width: 304px;
    }
    @media (min-width: 576px) {
        width: 560px;
    }
    @media (min-width: 768px) {
        width: 672px;
    }
    @media (min-width: 992px) {
        width: 928px;
    }
    @media (min-width: 1200px) {
        width: 1108px;
    }
    @media (min-width: 1600px) {
        width: 1168px;
    }
}

.blockTitle {
    font-weight: 700;
    color: #1E293B;
    @media (min-width: 320px) {
        font-size: 28px;
        line-height: 42px;
        padding-bottom: 35px;
        text-align: center;
    }
    @media (min-width: 576px) {
        text-align: left;
        font-size: 36px;
        line-height: 45px;
        padding-bottom: 32px;
    }
    @media (min-width: 992px) {
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 40px;
    }
}