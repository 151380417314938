@import '../../../../../assets/styles/landings/index.scss';

.HowItWork {
    background-color: #F5F7FA;
    padding-bottom: 40px;
    @media (min-width: 320px) {
        padding: 40px 0 0 0;
    }
    @media (min-width: 576px) {
        padding: 110px 0 0 0;
    }
    @media (min-width: 768px) {
        padding: 140px 0 0 0;
    }
    @media (min-width: 992px) {
        padding: 160px 0 0 0;
    }
    &__items {
        display: grid;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        @media (min-width: 320px) {
            grid-template-columns: 272px 272px 272px 272px;
            column-gap: 16px;
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {
            grid-template-columns: 320px 320px;
            column-gap: 32px;
            row-gap: 32px;
        }
        @media (min-width: 992px) {
            grid-template-columns: 448px 448px;
        }
        @media (min-width: 1200px) {
            grid-template-columns: 253px 253px 253px 253px;
        }
        @media (min-width: 1600px) {
            grid-template-columns: 268px 268px 268px 268px;
        }
    }
    &__card {
        background-color: #FFFFFF;
        border-radius: 3px;
        position: relative;
        @media (min-width: 320px) {
            padding: 24px 16px;
            min-height: 330px;
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {
            min-height: 370px;
            padding: 32px 24px;
            &:nth-child(4) {
                order: 3;
            }
            &:nth-child(3) {
                order: 4;
            }
        }
        @media (min-width: 992px) {
            min-height: 320px;
        }
        @media (min-width: 1200px) {
            min-height: 430px;
            &:nth-child(4) {
                order: 4;
            }
            &:nth-child(3) {
                order: 3;
            }
        }
    }
    &__cardTitle {
        font-weight: 500;
        color: #1E293B;
        margin-bottom: 16px;
        @media (min-width: 320px) {
            font-size: 18px;
            line-height: 27px;
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {
            font-size: 24px;
            line-height: 35px;
        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }
    }
    &__cardContent {
        font-weight: 400;
        color: #64748B;
        position: relative;
        z-index: 2;
        @media (min-width: 320px) {
            font-size: 16px;
            line-height: 24px;
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 27px;
        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }
    }
    &__cardContentList {
        @media (min-width: 320px) {
            display: block;
        }
        @media (min-width: 768px) {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 5px;
        }
        @media (min-width: 1200px) {
            display: block;
        }
    }
    &__cardIcon {
        position: absolute;
        color: #F5F7FA;
        bottom: -41px;
        opacity: 0.5;
        right: 0;
        z-index: 1;
        @media (min-width: 320px) {
            font-size: 129px;
            img  {
                max-width: 85%;
                position: relative;
                right: -22px;
            }
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {

        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1200px) {
            font-size: 143px;
            img  {
                max-width: 100%;
                position: relative;
                right: 0;
            }
        }
        @media (min-width: 1600px) {

        }
    }
    &__cardAction {
        margin-top: 24px;
        position: relative;
        z-index: 2;
        @media (min-width: 320px) {
            margin-top: 24px;
        }
        @media (min-width: 768px) {
            margin-top: 16px;
        }
        @media (min-width: 992px) {
            margin-top: 24px;
        }
    }
    &__cardActionButton {
        background: transparent;
        border-radius: 0;
        border: 2px solid #f87171;
        font-weight: 500;
    }
    &__tip {
        padding: 16px 27px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @media (min-width: 320px) {
            display: flex;
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {
            display: none;
        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }
    }
    &__tipIcon {
        font-size: 24px;
        color: #CBD5E1;
        margin-right: 8px;
    }
    &__tipText {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #CBD5E1;
    }
    &__scheme {
        color: #F87171;
        @media (min-width: 320px) {
            font-size: 16px;
        }
        @media (min-width: 576px) {

        }
        @media (min-width: 768px) {
            font-size: 18px;
        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1200px) {

        }
        @media (min-width: 1600px) {

        }
    }

    // Chanel icons
    &__chanelRight {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        @media (min-width: 320px) {
            right: -22px;
        }
        @media (min-width: 768px) {
            right: -32px;
        }
    }

    // Webmaster icons
    &__wmDollar {
        position: absolute;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) {
            top: 20%;
            right: -17px;
        }
        @media (min-width: 768px) {
            top: 105%;
            right: 30%;
        }
        @media (min-width: 992px) {
            top: 105.5%;
            right: 30%;
        }
        @media (min-width: 1200px) {
            top: 20%;
            right: -25px;
        }
    }

    &__wmLeft {
        position: absolute;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) {
            top: 30%;
            right: -22px;
        }
        @media (min-width: 768px) {
            top: 101%;
            right: 40%;
            transform: rotate(90deg);
        }
        @media (min-width: 1200px) {
            top: 30%;
            right: -25px;
            transform: rotate(0deg);
        }
    }
    &__wmRight {
        position: absolute;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) {
            top: 50%;
            right: -22px;
            transform: translate(-50%, -50%);
        }
        @media (min-width: 768px) {
            top: 101%;
            right: 70%;
            transform: rotate(90deg);
        }
        @media (min-width: 1200px) {
            top: 50%;
            right: -32px;
            transform: translate(-50%, -50%);
        }
    }

    // Affiliate icons
    &__affiliateDollar {
        position: absolute;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) {
            top: 20%;
            right: -17px;
        }
        @media (min-width: 768px) {
            top: 50%;
            right: 102%;
        }
        @media (min-width: 992px) {
            right: 101%;
        }
        @media (min-width: 1200px) {
            top: 20%;
            right: -25px;
        }
    }

    &__affiliateLeft {
        position: absolute;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) {
            top: 30%;
            right: -22px;
        }
        @media (min-width: 768px) {
            right: 100%;
        }
        @media (min-width: 992px) {
            right: 100%;
        }
        @media (min-width: 1200px) {
            top: 30%;
            right: -25px;
            transform: rotate(0deg);
        }
    }
    &__affiliateRight {
        position: absolute;
        transform: translate(-50%, -50%);
        @media (min-width: 320px) {
            top: 50%;
            right: -22px;
            transform: translate(-50%, -50%);
        }
        @media (min-width: 768px) {
            top: 60%;
            right: 100%;
        }
        @media (min-width: 1200px) {
            top: 50%;
            right: -32px;
            transform: translate(-50%, -50%);
        }
    }
}
